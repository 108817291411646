import React from 'react';
import ReactDOM from 'react-dom';
import './globals.css';
import App from './App';

import { Auth0Provider } from '@auth0/auth0-react';
import RoutePaths from './RoutePaths';
import './i18n';
import 'bootstrap/dist/css/bootstrap.min.css';

// this field is used to fix that: <Auth0Provider> could not work in http protocol.
// const isSecureUrl = window.location.protocol === 'https:';

// for auth0's special designation, the redirect_url must be end with "/profile". that sucks.

// In order to verify token with auth0's SDK in the backend,
// I must put the key: audience. this is according to "https://github.com/auth0/node-oauth2-jwt-bearer/issues/69#issuecomment-1191185036".

// 这里对redirect_uri 做了处理，如果是从localhost来的，那么就用localhost，如果是从*.scitudy.com来的，那么就用www.scitudy.com，这样就引导google搜索过来的用户自动跳转到www.scitudy.com

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      useRefreshTokens={true}
      cacheLocation={'localstorage'}
      domain="dev-wwlt7rh1fglv254g.jp.auth0.com"
      clientId="5BEhSXCCsGS6y3T65ELZrAUH29w3oXhN"
      authorizationParams={{
        redirect_uri:
          (window.location.origin.includes('localhost') ||
          window.location.origin.includes('beta.')
            ? window.location.origin
            : 'https://www.scitudy.com') + RoutePaths.PROFILE,
        audience: 'https://dev-wwlt7rh1fglv254g.jp.auth0.com/api/v2/',
        scope: 'openid profile email offline_access',
      }}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
  document.querySelector('#root')
);
